import {Injectable} from '@angular/core';
import {FabricPreview} from 'src/app/product-details/interface/fabric-preview';
import {FinishProfileItem} from 'src/app/product-details/interface/finish-profile-item-list';
import {MadeToOrderFabric} from 'src/app/product-details/interface/made-to-order-fabric';
import {SizeProfileOptionItem} from 'src/app/product-details/interface/size-profile-option-item';
import {Cart} from '../interface/cart';
import {Product} from 'src/app/product-details/interface/product';
import {JWTService} from 'src/app/authentication/jwt.service';
import {GetAndSetCartDataInLocalstorageService} from './set-cart-data-in-localstorage.service';
import {RaintreeResponse} from 'src/app/raintree/interface/raintree-response';
import {CartTransmissionService} from 'src/app/raintree/transmission/cart/cart-transmission.service';
import {RequestMapperService} from 'src/app/request-mapper.service';
import {ToastMessageService} from 'src/app/services/toast-message.service';
import {SettingsDataService} from "../../services/settings-data.service";
import {CheckForDuplicateCartDataService} from './check-for-duplicate-cart-data.service';
import {CustomSize} from '../interface/custom-size';
import {MadeToOrderProfile} from 'src/app/product-details/interface/made-to-order-profile';
import {FabricVolumeDiscuntCalculationService} from 'src/app/product-details/service/fabric-volume-discunt-calculation.service';
import {CustomSizeProfile} from "../../product-details/interface/custom-size-profile";
import {GtagAPIService} from "@bloomscorp/ngx-gtag";

@Injectable({
	providedIn: 'root'
})
export class CartCalculationService {

	public wholeProductData: Product = {} as Product;
	public selectedFabricItem: FabricPreview | MadeToOrderFabric | undefined = {} as FabricPreview | MadeToOrderFabric | undefined;
	public selectedSize: SizeProfileOptionItem | undefined = {} as SizeProfileOptionItem;
	public selectedFinishForCartCalc: FinishProfileItem[] = [];
	public zeroElementFinish: FinishProfileItem[] = [];
	public orderType: string = "";
	public previouslySelectedOrderType: string = "";
	public isPreorder: boolean = false;
	public fabricProductId: number | undefined = 0;
	public finishedProductId: number | undefined = 0;
	public selectedFabricProfileItemId = 0;
	public productGroup: string = "";
	public quantity: number = 1;
	public unit:string = "";
	public defaultProductPrice: number  = 0; // response product price
	public selectedConsumedFabric: number  = 0 // consumedFabric when price select
	public inStockFinishPrice: number  = 0 // total in stock finish price
	public swatchProduct: boolean = false;
	public makingCharge: number = 0;
	private _price: number  = 0;
	public customSize: CustomSize = {} as CustomSize
	public madeToOrderProfile: MadeToOrderProfile = {} as MadeToOrderProfile;
	public customSizeProfile: CustomSizeProfile = {} as CustomSizeProfile;
	public hasThisProductCustomSize:boolean = false;
	constructor(
		private _jwt: JWTService,
		private _GetSetCartInLocal: GetAndSetCartDataInLocalstorageService,
		private _cartTransmission: CartTransmissionService,
		private _toast: ToastMessageService,
		private _settings: SettingsDataService,
		private _getCartDataInLocal: GetAndSetCartDataInLocalstorageService,
		private _checkForDuplicateCartDataService: CheckForDuplicateCartDataService,
		private _fabricVolumeDiscountCalculation: FabricVolumeDiscuntCalculationService,
		private _gtag: GtagAPIService
		// private _priceCalculation: PriceCalculationService
	) {
	}

	public storeSwatchPrice: number = 0;
	public calculateCart(): void {

		// this._price = this.cartPriceCalculation();
		// console.log(this._price);

		// if(this._jwt.hasValidJWT()){
		this.initCartDataForPost();
		// }

	}

	public initCartDataForPost(): void {
		// debugger
		let isCartItemExist: boolean = false;

		let data: Cart = {
			fabricProductId: this.fabricProductId,
			finishedProductId: this.finishedProductId,
			selectedFabricId: this.selectedFabricProfileItemId ? this.selectedFabricProfileItemId : 0,
			selectedSizeOptionId: this.selectedSize?.id ? this.selectedSize?.id : 0,
			selectedFinishId: this.finishedIdStringify(),
			productGroup: this.swatchProduct ? 'swatch' : this.productGroup,
			orderType: this.swatchProduct ? 'IN_STOCK' : this.orderType,
			quantity: this.quantity,
			unit: this.unit,
			// selectedFabricProfileItemSlug: this.selectedFabricItem?.slug!,
			// for rendering in ui
			price: this.cartPriceCalculation(),
			productName: this.wholeProductData.name,
			image: this.wholeProductData.heroImage,
			fabricName: this.selectedFabricItem?.name,
			sizeName: this.selectedSize?.label,
			customSize: this.customSize,
			makingCharge: this._checkForDuplicateCartDataService.makingCharges,
			fabricPriceWithOutVD: this.cartPriceCalculation(),
		}

		if(data.customSize && Object.keys(data.customSize).length
			&& data.customSize && this.wholeProductData.customSizeProfileEnabled  && this.wholeProductData.customSizeProfile) {
			//TODO int custom size price
			// data.price = (data.price! + this.wholeProductData.customSizeProfile.price);

			this.customSizeProfile = this.wholeProductData.customSizeProfile;
		}

		if (data.fabricProductId == 0) delete data.fabricProductId;
		if (data.selectedSizeProfileOptionId == 0) delete data.selectedSizeProfileOptionId;
		if (data.finishedProductId == 0) delete data.finishedProductId;

		if (this.swatchProduct) {
			data.price = this.swatchPriceCalculation(data.price!, this._settings.swatchDiscount);
			data.copyPrice = this.swatchPriceCalculation(this.storeSwatchPrice, this._settings.swatchDiscount);
			delete data.selectedSizeProfileOptionId
			delete data.finishedProductId
		}
		if (this.isPreorder ? data.orderType = 'PRE_ORDER' : data.orderType = data.orderType)
		// 	debugger
		// console.log(data);

		if(data.customSize && Object.keys(data.customSize).length) {
			data.selectedSizeOptionId = 0
		}

		isCartItemExist = this._checkForDuplicateCartDataService.isCartItemExist(data);
		// debugger
		// return
		if (this._jwt.hasValidJWT()) {

			if(isCartItemExist) {
				// debugger
				let duplicateItem = this._checkForDuplicateCartDataService.duplicateObject;
				duplicateItem.quantity = duplicateItem.quantity + data.quantity;
				duplicateItem.makingCharge = duplicateItem.makingCharge;

				this.updateCartData(
					this._checkForDuplicateCartDataService.duplicateObject
				)
			}
			else {
				this.addCartData(data)
			}

			this._gtag.sendEventData({
					action: 'add_to_cart',
					event: 'add_to_cart',
					ecommerce: {
						items: [{
							item_name: data.productName,
							item_id: this.wholeProductData.sku,
							price: data.price,
							item_category: this.wholeProductData.category.name,
							quantity: data.quantity
						}]
					}

				}
			);
		}
		this._GetSetCartInLocal.temporaryCartData.push(data);
		this._GetSetCartInLocal.setCartInLocal(data);


	}

	public finishedIdStringify(): string {
		// debugger
		let id = [];
		id = this.removeDuplicate(this.selectedFinishForCartCalc).map((finished: FinishProfileItem) => finished.id)
		return id.join(",")
	}

	public cartPriceCalculation(): number {

		let calcPrice:number = 0;
		calcPrice = (
			(this.selectedConsumedFabric?this.selectedConsumedFabric: this.madeToOrderProfile.consumedFabric)
			* ((this.selectedFabricItem?.price) ? this.selectedFabricItem?.price : this.defaultProductPrice));

		console.log(calcPrice, this.defaultProductPrice, this.selectedConsumedFabric, this.madeToOrderProfile.consumedFabric, this.selectedFabricItem?.price);

		if(this.swatchProduct){
			if (!calcPrice) calcPrice = this.defaultProductPrice;
			this.storeSwatchPrice = calcPrice;
		}


		let WithoutVDFabricPriceWithConsumedFabric:number = calcPrice;
		let fabricVDprice:number = 0

		// TODO: do not remove start, this is fabric volume discount calculation
		// if(this.selectedFabricItem && this.selectedFabricItem.volumeDiscountProfile && this.selectedFabricItem.volumeDiscountProfile.id ) { // fabric volume discount calculation

		// 	fabricVDprice = this._fabricVolumeDiscountCalculation.calculateFabricVolumeDiscount(
		// 		calcPrice,
		// 		(this.quantity * (this.selectedConsumedFabric?this.selectedConsumedFabric: this.madeToOrderProfile.consumedFabric)),
		// 		this.selectedFabricItem.volumeDiscountProfile
		// 	)
		// }
		// TODO: do not remove end, this is fabric volume discount calculation

		calcPrice = (fabricVDprice ? fabricVDprice : WithoutVDFabricPriceWithConsumedFabric);
		console.log(calcPrice, this.defaultProductPrice, this.selectedConsumedFabric, this.madeToOrderProfile.consumedFabric, this.selectedFabricItem?.price);

		if (this.swatchProduct) {
			return this.swatchPriceCalculation(this.defaultProductPrice, this._settings.swatchDiscount)
		}

		let finishPrice = this._finishPriceCalc()
		if (this.selectedFinishForCartCalc.length > 0){
			calcPrice = (calcPrice + finishPrice );
		}
		console.log(calcPrice, this.defaultProductPrice, this.selectedConsumedFabric, this.madeToOrderProfile.consumedFabric, finishPrice);
		// if (this.selectedFinishForCartCalc.length > 0) calcPrice = calcPrice ;
		calcPrice = ((calcPrice?calcPrice:0) + this.makingCharge);

		console.log(calcPrice, this.defaultProductPrice, this.selectedConsumedFabric, this.makingCharge, finishPrice);


        if(this.hasThisProductCustomSize){
            calcPrice = calcPrice + this.customSizeProfile.price
        }
		return calcPrice
	}

	public fabricPriceWithoutVD(): number {
		let calcPrice = (
			(this.selectedConsumedFabric?this.selectedConsumedFabric: this.madeToOrderProfile.consumedFabric)
			* ((this.selectedFabricItem?.price) ? this.selectedFabricItem?.price : this.defaultProductPrice))
		if (!calcPrice) calcPrice = this.defaultProductPrice;
		return calcPrice
	}

	public addCartData(data: Cart) {
		this._cartTransmission.AddCartItem(
			RequestMapperService.ADD_CART_ITEM,
			data,
			() => {
			},
			() => {
			},
			(response: RaintreeResponse) => {
				// this._toast.success(response.message, response.message, 'bottom-center');
				this._getCartDataInLocal.openCartModal.next(true);
				this.isPreorder = false;
				this.quantity = 1;
				this.customSize = {};
			},
			(error: string) => {
				this._toast.error(error, error, 'bottom-center');
			},
			() => {
			}
		)
	}

	public updateCartData(data: Cart) {
		// debugger

		this._cartTransmission.updateCartItem(
			RequestMapperService.UPDATE_CART_ITEM,
			data,
			() => {
			},
			() => {
			},
			(response: RaintreeResponse) => {
				this._getCartDataInLocal.openCartModal.next(true);
				this.isPreorder = false;
				this.quantity = 1;
				this.customSize = {};
			},
			(error: string) => {
				// console.log("TODO add toaster: custom message: after login cart a add hoche na")
			},
			() => {
			}
		)
	}

	public swatchPriceCalculation(price: number, percent: number): number {

		if (price && percent) {
			const discount: number = (percent / 100) * price;
			return Math.round(discount);
		}
		return 0;
	}
	//TODO: do not remove
	// public setCartDataInLocal(data: Cart[]) {
	// 	localStorage.removeItem("cartData");
	// 	localStorage.setItem("cartData", JSON.stringify(data))
	// }
	//TODO: do not remove

	private _finishPriceCalc(): number {

		if (!this.inStockFinishPrice) { // in on load  selected cart price calculation form cart-response

			let inStockPriceOnLoad: number[] = [];

			this.selectedFinishForCartCalc.forEach(elm => {
				inStockPriceOnLoad.push(elm.price);
			});

			let priceTotal: number = inStockPriceOnLoad.reduce(function (prev, cur) {
				return prev + cur;
			}, 0);

			this.inStockFinishPrice = priceTotal;
		}

		if (this.selectedFinishForCartCalc[0]) {
			let price: number[] = [];
			this.selectedFinishForCartCalc.forEach((finish: FinishProfileItem) => {
				price.push(finish.price)
			});
			const arrSum = price.reduce((sum, current) => sum + current, 0);
			return arrSum
		} else {
			return 0
		}


	}


	public removeDuplicate(arr: FinishProfileItem[]): FinishProfileItem[] {
		const ids = arr.map(o => o.id); // remove duplicate
		const filtered = arr.filter(({ id }, index) => !ids.includes(id, index + 1));
		return filtered;
	}

}
