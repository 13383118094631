<section class="fb-home-blog-new w-full justify-center items-center py-10 hidden lg:flex">
	<div *ngIf="!loading" class="container flex flex-col justify-between items-center">
		<div class="fb-blog-gallery grid-1 lg:mt-5">
			<div class="w-full">
				<h2 class="text-6xl">All</h2>
				<h2 class="text-7xl text-[#7D5B20] font-medium">
					{{storyContentType}}
				</h2>
				<a routerLink="/story"
				   target="_blank"
				   class="text-xl py-2 fb_animate_icon_button">
					<i class="fb_animate"><b ></b><span></span></i>
					Discover More
				</a>
			</div>
			<div class="fb-blog-container" *ngIf="storyPreview[0]">
				<div class="fb-blog-content">
					<h3 class="fb-blog-title">{{storyPreview[0].title}}</h3>
					<p class="fb-blog-description" [innerHTML]="storyPreview[0].description | truncketHtml:250">
					</p>
					<a routerLink="{{'/story-details/'+storyPreview[0].slug+'/'+storyPreview[0].id}}"
					   target="_blank"
					   class="text-base py-2 fb_story_button ">
						<button>Learn More</button>
						<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8-8-8z"/></svg>
					</a>
				</div>
				<img class="fb-blog-image"
					 lazyLoad
					 [src]="storyPreview[0].bannerImageDesktop"
					 [alt]="storyPreview[0].title"/>
			</div>
			<div class="fb-blog-container" *ngIf="storyPreview[1]">
				<div class="fb-blog-content">
					<h3 class="fb-blog-title">{{storyPreview[1].title}}</h3>
					<p class="fb-blog-description" [innerHTML]="storyPreview[1].description | truncketHtml:250">
					</p>
					<a routerLink="{{'/story-details/'+storyPreview[1].slug+'/'+storyPreview[1].id}}"
					   target="_blank"
					   class="text-base py-2 fb_story_button ">
						<button>Learn More</button>
						<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8-8-8z"/></svg>
					</a>
				</div>
				<img class="fb-blog-image"
					 lazyLoad
					 [src]="storyPreview[1].bannerImageDesktop"
					 [alt]="storyPreview[1].title"/>
			</div>
		</div>
		<div class="fb-blog-gallery grid-2 lg:mt-5">
			<div class="fb-blog-container" *ngIf="storyPreview[2]">
				<div class="fb-blog-content">
					<h3 class="fb-blog-title">{{storyPreview[2].title}}</h3>
					<p class="fb-blog-description" [innerHTML]="storyPreview[2].description | truncketHtml:250">
					</p>
					<a routerLink="{{'/story-details/'+storyPreview[2].slug+'/'+storyPreview[2].id}}"
					   target="_blank"
					   class="text-base py-2 fb_story_button ">
						<button>Learn More</button>
						<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8-8-8z"/></svg>
					</a>
				</div>
				<img class="fb-blog-image"
					 lazyLoad
					 [src]="storyPreview[2].bannerImageDesktop"
					 [alt]="storyPreview[2].title" />
			</div>
			<div class="fb-product-container grid-product-2">
				<ng-container *ngFor="let product of products">
					<a [routerLink]="blogView.getProductLink(product)"
					   class="fb-fp-card flex flex-col justify-center items-center relative">
						<img class="fb-fp-image" lazyLoad [src]="product.heroImage" [alt]="product.name">
						<div
							class="w-[90%] max-w-[300px] flex justify-between items-center fb-fp-view  px-2 py-1.5 absolute bottom-5">
							<p class="text-white text-xs sm:text-sm font-semibold">
								{{product.name | truncketHtml: 15}}
							</p>
							<button class="rounded-xl text-white bg-[#6c5b48] px-2.5 py-1">View</button>
						</div>
					</a>
				</ng-container>
			</div>
		</div>
	</div>
</section>
<fb-home-blog-view-mobile class="block lg:hidden"
						  [storyPreview]="storyPreview"
						  [products]="products"
						  [loading]="loading"
						  [storyContentType]="'Crafts'"></fb-home-blog-view-mobile>
