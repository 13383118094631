import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {JWTService} from "src/app/authentication/jwt.service";
import {BlogPreview} from "src/app/navigation/interface/story-preview";
import {PasteboxService} from "src/app/services/pastebox.service";
import {HttpErrorHandlerService} from "../../http-error-handler.service";
import {TransmissionService} from "../transmission.service";
import {BlogResponse} from "../../interface/blog-response/blog-response";
import {ConstantService} from "../../../services/constant.service";
import {RequestMapperService} from "../../../request-mapper.service";
import {BlogContentDetails} from "../../../custom-content/interface/blog-content-details";
import {BlogContentDetailsPayload} from "../../interface/blog-content-details-payload";

@Injectable({
	providedIn: 'root'
})
export class BlogTransmissionService extends TransmissionService {
	constructor(
		_http: HttpClient,
		_httpErrorHandler: HttpErrorHandlerService,
		_jwt: JWTService,
        _pastebox: PasteboxService
	) {
		super(_http, _httpErrorHandler, _jwt, _pastebox);
	}
	public getBlogList(
		url:string,
		onPreExecute: () => void,
		onPostExecute: (response: BlogPreview[]) => void,
		onSuccess: (response: BlogPreview[]) => void,
		onFailure: (error: string) => void,
		onComplete: () => void,
	){
		this.executeGetPayload<BlogResponse,BlogPreview[]>(
			url,
			onPreExecute,
			onPostExecute,
			onSuccess,
			onFailure,
			onComplete,
			ConstantService.BLOG_CONTENT_LIST_ATTRIBUTE_KEY,
			false
		);
	}

	public getBlogListByCategory(
		id: number,
		onPreExecute: () => void,
		onPostExecute: (response: BlogPreview[]) => void,
		onSuccess: (response: BlogPreview[]) => void,
		onFailure: (error: string) => void,
		onComplete: () => void,
	){
		this.executeGetPayload<BlogResponse,BlogPreview[]>(
			RequestMapperService.GET_BLOG_LIST_BY_CATEGORY + id,
			onPreExecute,
			onPostExecute,
			onSuccess,
			onFailure,
			onComplete,
			ConstantService.BLOG_CONTENT_LIST_ATTRIBUTE_KEY,
			false
		);
	}

	public getCustomContentDetails(
		id: string,
		onPreExecute: () => void,
		onPostExecute: (response: BlogContentDetails) => void,
		onSuccess: (response: BlogContentDetails) => void,
		onFailure: (error: string) => void,
		onComplete: () => void,
	): void {
		this.executeGetPayload<BlogContentDetailsPayload, BlogContentDetails>(
			RequestMapperService.GET_BLOG_CONTENT + id,
			onPreExecute,
			onPostExecute,
			onSuccess,
			onFailure,
			onComplete,
			ConstantService.BLOG_CONTENT_ATTRIBUTE_KEY,
			false
		);
	}

}
