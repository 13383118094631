<menu class="fb-s-navigation-mobile block xl:hidden fixed w-[89%] left-0 mt-6"
	  [ngClass]="isMobileMenuOpen ? 'open': 'close'">
	<li *ngIf="isLoggedIn" class="text-base w-full fb-snv-option font-bold flex justify-between items-center">
		<a routerLink="/profile"
		   (click)="closeMenu()"
		   class="transition flex lg:hidden items-center justify-start gap-1 w-full">
                <span  class="material-symbols-outlined">
                            person
                </span>

			<span class="text-sm">{{tenantName}}</span>
		</a>
		<a routerLink="/profile" (click)="closeMenu()">
			<span class="material-symbols-outlined" >
			chevron_right
		</span>
		</a>

	</li>
	<li class="fb-snv-option mobile me text-sm flex justify-between items-center">
		<span>Currency:</span>
		<fb-navigation-forex-dropdown class=""></fb-navigation-forex-dropdown>
	</li>
	<li class="fb-snv-option mobile flex justify-between items-center">
		<a [href]="generateUrl.generateSegmentRedirectionLink('products/fabric', 'swatchkit', 'swatchkits')"
		   class="bg-[#B7A98F] text-center text-white rounded-md cursor-pointer text-base whitespace-nowrap m-1 p-2 w-full">
			Order a SwatchKit
		</a>
	</li>
	<li class="fb-snv-option" [ngClass]="selectedMenuOption === 'fabric'? 'option-item-active':''">
		<div class="main w-full flex justify-between items-center mb-2">
			<a href="/products/fabric" class="fb-s-nav-main">Fabric</a>
			<span class="material-symbols-outlined" (click)="triggerAccordion('fabric')">
				expand_more
			</span>
		</div>

		<div *ngIf="!navigationCraft.isLoading &&
			!navigationMaterial.isLoading &&
			!navigationColor.isLoading &&
			!navigationPattern.isLoading"
			 class="fb-snv-m-dropdown fb-fabric-dropdown">
			<div class="flex  flex-col justify-between items-stretch gap-1">
				<div class="fb-snv-m-dropdown-option color-tetradic-3 rounded-md m-1"
					 [ngClass]="selectedSubMenuOption === 'craft'? 'option-item-active':''">
					<div class="w-full flex justify-between items-center p-2 ">
						<h2 class="fb-snv-main font-medium ">Crafts</h2>
						<span class="material-symbols-outlined" (click)="triggerSubAccordion('craft')">
							expand_more
						</span>
					</div>
					<div class="grid grid-cols-1 sm:grid-cols-2">
						<div *ngFor="let segment of navigationCraft.craft"
							 class="fb-sn-section rounded px-3 py-2 mx-1 fb-default-transition ">
							<div class="fb-sn-category capitalize font-bold mb-2">
								<li><a [href]="generateUrl.generateCategoryRedirectionLink('products/fabric', segment)"
									   class="cursor-pointer">{{segment.segmentCategoryName}}</a></li>
							</div>
							<div *ngFor="let subCategory of segment.optionList"
								 class="fb-sn-sub-category">
								<li *ngIf="subCategory.subCategoryName !== 'Custom Product'"
									class="my-[3px]">
									<a *ngIf="subCategory.subCategoryName.toLowerCase() !== 'swatchkits'"
									   [href]="generateUrl.generateSegmentRedirectionLink('products/fabric', segment.segmentCategoryName, subCategory.subCategoryName)"
									   class="capitalize hover:underline cursor-pointer">
										{{subCategory.subCategoryName.toLowerCase()}}
									</a>
								</li>
							</div>
						</div>
					</div>


				</div>

				<div class="fb-snv-m-dropdown-option grid grid-cols-1 color-tetradic-1 rounded-md m-1"
					 [ngClass]="selectedSubMenuOption === 'material'? 'option-item-active':''">
					<div class="w-full">
						<div class="w-full flex justify-between items-center p-2">
							<h2 class="fb-snv-main font-medium ">Material</h2>
							<span class="material-symbols-outlined" (click)="triggerSubAccordion('material')">
							expand_more
						</span>
						</div>
						<div *ngFor="let material of navigationMaterial.materials" class="fb-sn-section">
							<li class="my-[3px] mx-2">
								<a [href]="generateUrl.generateRedirectionLink('products/fabric', 'material', material.materialName)"
								   class="capitalize hover:underline cursor-pointer">
									{{material.materialName.toLowerCase()}}
								</a>
							</li>
						</div>
					</div>
				</div>

				<div class="fb-snv-m-dropdown-option grid grid-cols-1 color-tetradic-2 rounded-md m-1"
					 [ngClass]="selectedSubMenuOption === 'pattern'? 'option-item-active':''">
					<div class="w-full ">
						<div class="w-full flex justify-between items-center p-2 ">
							<h2 class="fb-snv-main font-medium ">Pattern</h2>
							<span class="material-symbols-outlined" (click)="triggerSubAccordion('pattern')">
							expand_more
						</span>
						</div>
						<div *ngFor="let pattern of navigationPattern.patterns" class="fb-sn-section">
							<li class="my-[3px] mx-2">
								<a [href]="generateUrl.generateRedirectionLink('products/fabric', 'pattern', pattern.patternName)"
								   class="capitalize hover:underline cursor-pointer">
									{{pattern.patternName.toLowerCase()}}
								</a>
							</li>
						</div>
					</div>
				</div>

				<div class="fb-snv-m-dropdown-option grid grid-cols-1 rounded-md m-1"
					 [ngClass]="selectedSubMenuOption === 'color'? 'option-item-active':''">
					<div class="w-full">
						<div class="w-full flex justify-between items-center p-2 ">
							<h2 class="fb-snv-main font-medium ">Color</h2>
							<span class="material-symbols-outlined" (click)="triggerSubAccordion('color')">
							expand_more
						</span>
						</div>
						<div *ngFor="let color of navigationColor.colors; let i = index" class="fb-sn-section">
							<li class="flex justify-start items-center gap-1.5 mx-2">
                                    <span class="w-7 h-7"
										  [ngClass]="[i === 0? 'rounded-top':'', i === (navigationColor.colors.length -1)? 'rounded-bottom': '']"
										  [ngStyle]="{'background-color': color.colorHexCode}"></span>
								<a [href]="generateUrl.generateRedirectionLink('products/fabric', 'color', color.colorLabel)"
								   class="capitalize cursor-pointer">
									{{color.colorLabel.toLowerCase()}}
								</a>
							</li>
						</div>
					</div>
				</div>


			</div>

		</div>

	</li>

	<li class="fb-snv-option" [ngClass]="selectedMenuOption === 'accessories'? 'option-item-active':''">
		<div class="main w-full flex justify-between items-center mb-2">
			<a href="/products/finished?category=accessories" class="fb-s-nav-main">Accessories</a>
			<span class="material-symbols-outlined" (click)="triggerAccordion('accessories')">
				expand_more
			</span>
		</div>

		<div *ngIf="!navigationAccessories.isLoading"
			 class="fb-snv-m-dropdown fb-fabric-dropdown">
			<div class="flex  flex-col justify-between items-stretch gap-1">
				<div class="fb-snv-m-dropdown-option color-complementary rounded-md m-1 option-item-active">
					<div class="grid grid-cols-1 sm:grid-cols-2">
						<div *ngFor="let segment of navigationAccessories.accessories"
							 class="fb-sn-section rounded px-3 py-2 mx-1 fb-default-transition ">
							<div class="fb-sn-category capitalize font-bold mb-2">
								<li><a [href]="generateUrl.generateCategoryRedirectionLink('products/finished', segment)"
									   class="cursor-pointer">{{segment.segmentCategoryName}}</a></li>
							</div>
							<div *ngFor="let subCategory of segment.optionList"
								 class="fb-sn-sub-category">
								<li *ngIf="subCategory.subCategoryName !== 'Custom Product'"
									class="my-[3px]">
									<a [href]="generateUrl.generateSegmentRedirectionLink('products/finished', segment.segmentCategoryName, subCategory.subCategoryName)"
									   class="capitalize hover:underline cursor-pointer">
										{{subCategory.subCategoryName.toLowerCase()}}
									</a>
								</li>
							</div>
						</div>
					</div>


				</div>
			</div>

		</div>

	</li>
	<li class="fb-snv-option" [ngClass]="selectedMenuOption === 'homeware'? 'option-item-active':''">
		<div class="main w-full flex justify-between items-center mb-2">
			<a href="/products/finished?category=home" class="fb-s-nav-main">Homeware</a>
			<span class="material-symbols-outlined" (click)="triggerAccordion('homeware')">
				expand_more
			</span>
		</div>

		<div *ngIf="!navigationHome.isLoading"
			 class="fb-snv-m-dropdown fb-fabric-dropdown">
			<div class="flex  flex-col justify-between items-stretch gap-1">
				<div class="fb-snv-m-dropdown-option color-analogous-1 rounded-md m-1 option-item-active">
					<div class="grid grid-cols-1 sm:grid-cols-2">
						<div *ngFor="let segment of navigationHome.home"
							 class="fb-sn-section rounded px-3 py-2 mx-1 fb-default-transition ">
							<div class="fb-sn-category capitalize font-bold mb-2">
								<li><a [href]="generateUrl.generateCategoryRedirectionLink('products/finished', segment)"
									   class="cursor-pointer">{{segment.segmentCategoryName}}</a></li>
							</div>
							<div *ngFor="let subCategory of segment.optionList"
								 class="fb-sn-sub-category">
								<li *ngIf="subCategory.subCategoryName !== 'Custom Product'"
									class="my-[3px]">
									<a [href]="generateUrl.generateSegmentRedirectionLink('products/finished', segment.segmentCategoryName, subCategory.subCategoryName)"
									   class="capitalize hover:underline cursor-pointer">
										{{subCategory.subCategoryName.toLowerCase()}}
									</a>
								</li>
							</div>
						</div>
					</div>


				</div>
			</div>

		</div>

	</li>
	<li class="fb-snv-option" [ngClass]="selectedMenuOption === 'apparel'? 'option-item-active':''">
		<div class="main w-full flex justify-between items-center mb-2">
			<a href="/products/finished?category=apparel" class="fb-s-nav-main">Apparel</a>
			<span class="material-symbols-outlined" (click)="triggerAccordion('apparel')">
				expand_more
			</span>
		</div>

		<div *ngIf="!navigationApparel.isLoading"
			 class="fb-snv-m-dropdown fb-fabric-dropdown">
			<div class="flex  flex-col justify-between items-stretch gap-1">
				<div class="fb-snv-m-dropdown-option color-analogous-2 rounded-md m-1 option-item-active">
					<div class="grid grid-cols-1 sm:grid-cols-2">
						<div *ngFor="let segment of navigationApparel.apparel"
							 class="fb-sn-section rounded px-3 py-2 mx-1 fb-default-transition ">
							<div class="fb-sn-category capitalize font-bold mb-2">
								<li><a [href]="generateUrl.generateCategoryRedirectionLink('products/finished', segment)"
									   class="cursor-pointer">{{segment.segmentCategoryName}}</a></li>
							</div>
							<div *ngFor="let subCategory of segment.optionList"
								 class="fb-sn-sub-category">
								<li *ngIf="subCategory.subCategoryName !== 'Custom Product'"
									class="my-[3px]">
									<a [href]="generateUrl.generateSegmentRedirectionLink('products/finished', segment.segmentCategoryName, subCategory.subCategoryName)"
									   class="capitalize hover:underline cursor-pointer">
										{{subCategory.subCategoryName.toLowerCase()}}
									</a>
								</li>
							</div>
						</div>
					</div>


				</div>
			</div>

		</div>

	</li>

	<li class="fb-snv-option" [ngClass]="selectedMenuOption === 'collaborations'? 'option-item-active':''">
		<div class="main w-full flex justify-between items-center mb-2">
			<a href="/story" class="fb-s-nav-main">Collaborations</a>
			<span class="material-symbols-outlined" (click)="triggerAccordion('collaborations')">
				expand_more
			</span>
		</div>

		<div *ngIf="!navigationStoryClusters.isLoading &&
			!navigationStoryCollaborations.isLoading &&
			!navigationStoryCrafts.isLoading"
			 class="fb-snv-m-dropdown fb-fabric-dropdown">
			<div class="flex flex-col justify-between items-stretch gap-1">
				<div class="fb-snv-m-dropdown-option color-tetradic-3 rounded-md m-1"
					 [ngClass]="selectedSubMenuOption === 'crafts'? 'option-item-active':''">
					<div (click)="triggerSubAccordion('crafts')" class="w-full flex justify-between items-center p-2 ">
						<h2 class="fb-snv-main font-medium ">Crafts</h2>
						<span class="material-symbols-outlined" >
							expand_more
						</span>
					</div>
					<div class="grid grid-cols-1 sm:grid-cols-2">
						<div *ngFor="let story of navigationStoryCrafts.crafts"
							 class="fb-sn-section rounded px-3 py-2 mx-1 fb-default-transition">
							<div class="fb-sn-category capitalize font-bold mb-2">
								<li><a class="">{{story.storyCategoryName.toLowerCase()}}</a></li>
							</div>
							<div *ngFor="let subCategory of story.optionList"
								 class="fb-sn-sub-category">
								<li class="my-[3px]">
									<a href="/story-details/{{subCategory.slug}}/{{subCategory.storyId}}"
									   class="capitalize hover:underline cursor-pointer">
										{{subCategory.storyTitle.toLowerCase()}}
									</a>
								</li>
							</div>
						</div>
					</div>

				</div>
				<div class="fb-snv-m-dropdown-option color-tetradic-2 rounded-md m-1"
					 [ngClass]="selectedSubMenuOption === 'clusters'? 'option-item-active':''">
					<div (click)="triggerSubAccordion('clusters')" class="w-full flex justify-between items-center p-2 ">
						<h2 class="fb-snv-main font-medium ">Clusters</h2>
						<span class="material-symbols-outlined" >
							expand_more
						</span>
					</div>
					<div class="grid grid-cols-1 sm:grid-cols-2">
						<div *ngFor="let story of navigationStoryClusters.clusters"
							 class="fb-sn-section rounded px-3 py-2 mx-1 fb-default-transition">
							<div class="fb-sn-category capitalize font-bold mb-2">
								<li><a class="">{{story.storyCategoryName.toLowerCase()}}</a></li>
							</div>
							<div *ngFor="let subCategory of story.optionList"
								 class="fb-sn-sub-category">
								<li class="my-[3px]">
									<a href="/story-details/{{subCategory.slug}}/{{subCategory.storyId}}"
									   class="capitalize hover:underline cursor-pointer">
										{{subCategory.storyTitle.toLowerCase()}}
									</a>
								</li>
							</div>
						</div>
					</div>

				</div>
				<div class="fb-snv-m-dropdown-option color-tetradic-1 rounded-md m-1"
					 [ngClass]="selectedSubMenuOption === 'collab'? 'option-item-active':''">
					<div (click)="triggerSubAccordion('collab')"
						 class="w-full flex justify-between items-center p-2 ">
						<h2 class="fb-snv-main font-medium ">Collaborations</h2>
						<span class="material-symbols-outlined" >
							expand_more
						</span>
					</div>
					<div class="grid grid-cols-1 sm:grid-cols-2">
						<div *ngFor="let story of navigationStoryCollaborations.collaborations"
							 class="fb-sn-section rounded px-3 py-2 mx-1 fb-default-transition">
							<div class="fb-sn-category capitalize font-bold mb-2">
								<li><a class="">{{story.storyCategoryName.toLowerCase()}}</a></li>
							</div>
							<div *ngFor="let subCategory of story.optionList"
								 class="fb-sn-sub-category">
								<li class="my-[3px]">
									<a href="/story-details/{{subCategory.slug}}/{{subCategory.storyId}}"
									   class="capitalize hover:underline cursor-pointer">
										{{subCategory.storyTitle.toLowerCase()}}
									</a>
								</li>
							</div>
						</div>
					</div>

				</div>
			</div>

		</div>

	</li>

	<li class="fb-snv-option">
		<div class="main w-full flex justify-between items-center mb-2">
			<a href="/blogs" class="fb-s-nav-main">Stories</a>
		</div>
	</li>

	<li class="fb-snv-option" [ngClass]="selectedMenuOption === 'about'? 'option-item-active':''">
		<div (click)="triggerAccordion('about')" class="main w-full flex justify-between items-center mb-2">
			<a href="/story" class="fb-s-nav-main">About Us</a>
			<span class="material-symbols-outlined" >
				expand_more
			</span>
		</div>

		<div class="fb-snv-m-dropdown fb-fabric-dropdown">
			<div class="flex flex-col justify-between items-stretch gap-1">
				<div class="fb-snv-m-dropdown-option  color-tetradic-1 rounded m-1"
					 [ngClass]="selectedSubMenuOption === 'about-us'? 'option-item-active':''">
					<div (click)="triggerSubAccordion('about-us')"
						 class="w-full flex justify-between items-center p-2 ">
						<h2 class="fb-snv-main font-medium ">About Us</h2>
						<span class="material-symbols-outlined" >
							expand_more
						</span>
					</div>
					<div class="fb-sn-section rounded px-3 py-2 mx-1 fb-default-transition">
						<a href="/content/about-us/about-the-brand/56485" target="_blank">
							<li>
								About The Brand
							</li>
						</a>
						<a href="/content/about-us/about-our-impact/57938" target="_blank">
							<li>
								About Our Impact
							</li>
						</a>
						<a href="/content/about-us/about-the-founder/57073" target="_blank">
							<li>
								About the Founder
							</li>
						</a>
						<a href="/content/about-us/about-anuprerna-studio/53794" target="_blank">
							<li>
								About the studio
							</li>
						</a>
						<a routerLink="/contact">
							<li class="w-max bg-[#8FB7A3] text-white rounded-md px-2 py-1 cursor-pointer text-base whitespace-nowrap">
								Contact Us
							</li>
						</a>
					</div>
				</div>

				<div class="fb-snv-m-dropdown-option  color-tetradic-2 rounded m-1"
					 [ngClass]="selectedSubMenuOption === 'wholesale'? 'option-item-active':''">
					<div (click)="triggerSubAccordion('wholesale')"
						 class="w-full flex justify-between items-center p-2 ">
						<h2 class="fb-snv-main font-medium ">Wholesale</h2>
						<span class="material-symbols-outlined" >
							expand_more
						</span>
					</div>
					<div class="fb-sn-section rounded px-3 py-2 mx-1 fb-default-transition">
						<a href="/content/wholesale/wholesale-production-preorder/59335" target="_blank">
							<li>
								Wholesale Production process
							</li>
						</a>
						<a href="/content/wholesale/order-fabric-swatches/59195" target="_blank">
							<li>
								Order Fabric Swatches
							</li>
						</a>
						<a href="/content/wholesale/natural-sustainable-custom-dyeing/59105" target="_blank">
							<li>
								Custom Sustainable Dyeing
							</li>
						</a>
						<a href="/content/wholesale/custom-clothing-accessories-homewares/703160" target="_blank">
							<li>
								Custom Clothing, Accessories, <br/>& Homewares
							</li>
						</a>
					</div>

				</div>

				<div class="fb-snv-m-dropdown-option  color-complementary-2 rounded m-1"
					 [ngClass]="selectedSubMenuOption === 'careguide'? 'option-item-active':''">
					<div (click)="triggerSubAccordion('careguide')"
						 class="w-full flex justify-between items-center p-2 ">
						<h2 class="fb-snv-main font-medium ">Care Guide</h2>
						<span class="material-symbols-outlined" >
							expand_more
						</span>
					</div>
					<div class="fb-sn-section rounded px-3 py-2 mx-1 fb-default-transition">
						<a href="/content/care-guide/how-to-nurture-your-natural-dyed-clothing/126408" target="_blank">
							<li>
								Natural Dyed Fabric CareGuide
							</li>
						</a>
						<a href="/content/care-guide/handmade-textiles-care-guide/108968" target="_blank">
							<li>
								Handmade Textiles CareGuide
							</li>
						</a>
					</div>

				</div>

			</div>

		</div>

	</li>
	<li *ngIf="isLoggedIn" class="fb-snv-option icons">
		<div class="main w-full flex justify-between items-center mb-2 font-bold">
			<div (click)="logout()" class="option flex gap-2 items-center">
				<span class="material-symbols-outlined">
					logout
			</span>
				Logout
			</div>
		</div>
	</li>
	<li class="fb-snv-option icons">

		<div class="main w-full flex justify-between items-center mb-2">
			<button [routerLink]="['contact']" (click)="isMobileMenuOpen = false" class="flex gap-2 items-center">
				<span>
					<img class="light_image" src="./assets/img/contact-color.png" alt="contact">
				</span>
				<b>Contact us</b>
			</button>
		</div>
	</li>

	<li class="fb-snv-option icons">

		<div class="main w-max flex justify-between items-center mb-2 gap-2">
			<a href="https://twitter.com/Anuprerna6"><img src="assets/img/twitter.svg" alt="twiiter"/></a>
			<a href="https://www.facebook.com/anuprernatelier/"><img src="assets/img/facebook.svg" alt="facebook"/></a>
			<a href="https://in.pinterest.com/anuprernas/"><img src="assets/img/pininterest.svg" alt="pinterest"/></a>
			<a href="https://www.instagram.com/anuprerna_atelier/"><img src="assets/img/instagram.svg" alt="instagram"/></a>
			<a href="https://www.linkedin.com/company/anuprerna/"><img src="assets/img/linkedin-anuprerna.svg" alt="linkedin"/></a>
		</div>
	</li>
</menu>
<div class="overlay_mobile_menu {{isMobileMenuOpen?'active':''}}" (click)="closeMenu()"></div>
