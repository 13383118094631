<fb-home-caraousel></fb-home-caraousel>
<fb-home-manufacturing-process></fb-home-manufacturing-process>
<fb-home-featured-products></fb-home-featured-products>
<!--<fb-home-media-section id="media-section-home"></fb-home-media-section>-->
<ng-container *ngIf="craftStoryData.length > 0">
	<fb-home-blog-view [storyContentType]="'Crafts'"></fb-home-blog-view>
</ng-container>
<ng-container *ngIf="collaborationStoryData.length > 0">
	<fb-home-blog-view-2 [storyContentType]="'Collaborations'"></fb-home-blog-view-2>
</ng-container>
<ng-container *ngIf="clusterStoryData.length>0">
	<fb-home-blog-view-3 [storyPreview]="clusterStoryData.slice(0,3)"
						 [storyContentType]="'Clusters'"></fb-home-blog-view-3>
</ng-container>
<ng-container *ngIf="blogList && blogList.length > 0">
	<fb-home-all-stories [blogPreviewList]="blogList"></fb-home-all-stories>
</ng-container>
<div class="white_container_with_motif">
	<fb-home-third-party></fb-home-third-party>
</div>
<ng-container *ngIf="reviewList && reviewList.length > 0">
	<fb-home-review-carousel [reviews]="reviewList"></fb-home-review-carousel>
</ng-container>
